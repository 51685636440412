import { Link, graphql } from 'gatsby';
import { BreadcrumbJsonLd } from 'gatsby-plugin-next-seo';
import React, { useEffect, useMemo, useState } from 'react';

import { Box, Button, Container, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useLocation } from '@reach/router';

import { useFindRetreatIdByTitle } from '../hooks';
import SiteLayout, { ResponsiveContext } from '../layouts/site.layout';
import { CmsImage, CmsPage } from '../types';
import { generateBreadcrumbsList } from '../utils';
import { TemplateProps } from './template.types';

const useStyles = makeStyles(() => {
  return {
    navOffset: {
      marginTop: 56,
    },
    filter: {
      marginTop: 71,
    },
    filterBtn: {
      margin: '0 .5rem',
    },
    bigImage: {
      height: 673,
      width: '100%',
      objectFit: 'cover',
    },
    smallImage: {
      height: 330,
      width: '100%',
      objectFit: 'cover',
    },
    retreatLink: {
      textDecoration: 'none',
      color: 'initial',
    },
    suburb: {
      color: '#818181',
    },
    title: {
      color: '#151515',
    },
    flag: {
      fontSize: '12px',
      fontWeight: 'bold',
      textAlign: 'center',
      padding: '6px 0',
      position: 'absolute',
      width: '100%',
      bottom: 0,
      backgroundColor: '#e43530',
      color: 'white',
    },
  };
});

export const query = graphql`
  query getRetreatList($pageId: ID) {
    cms {
      pageBuilder {
        getPublishedPage(id: $pageId) {
          data {
            id
            title
            path
            content
          }
        }
      }
      retreats {
        allRetreats {
          id
          slug
          title
          photos {
            id
            src
            name
          }
          address {
            suburb
            state
          }
        }
      }
    }
  }
`;

type States = '' | 'ACT' | 'NSW' | 'NT' | 'QLD' | 'SA' | 'TAS' | 'VIC' | 'WA';

type CmsRetreat = {
  id: string;
  title: string;
  slug: string;
  photos: CmsImage[];
  address: {
    suburb: string;
    state: States;
  };
  createdOn: string;
  savedOn: string;
};

type RetreatTemplateProps = TemplateProps & {
  data: {
    cms: {
      pageBuilder: {
        getPublishedPage: {
          data: CmsPage;
        };
      };
      retreats: {
        allRetreats: CmsRetreat[];
      };
    };
  };
};

const seoMeta = {
  title: 'Luxurious Pet Accommodation & Retreats - Dolittles',
  description:
    'Indulge Your Pets in Premium Accommodation & Retreats at Dolittles Pet Resort. A Serene Haven for Your Companions. Book Their Getaway Today!',
  meta: [
    {
      name: 'og:title',
      content: 'Pet Retreats',
    },
    {
      name: 'og:description',
      content:
        'Welcome to the Dolittles Pet Retreats portal, your gateway to a world of exceptional pet-friendly escapes.',
    },
    {
      name: 'og:site_name',
      content: 'Dolittles',
    },
    {
      name: 'og:url',
      content: 'https://www.dolittles.com.au/retreats',
    },
    {
      name: 'og:type',
      content: 'business.business',
    },
    {
      name: 'og:image',
      content:
        'https://d2u7zqrutfs5vk.cloudfront.net/files/8leskp63a-9kyrtiymb-20210924-DolittlesPhotoshoot-204.jpg',
    },
  ],
};

export const Retreat: React.FC<RetreatTemplateProps> = ({
  ...templateProps
}) => {
  const {
    data: { cms },
  } = templateProps;
  const classes = useStyles();
  const [activeState, setActiveState] = useState('All');
  const [filteredRetreats, setFilteredRetreats] = useState<CmsRetreat[]>([]);
  const { pathname } = useLocation();
  const darwinRetreatId = useFindRetreatIdByTitle(
    'Bees Creek Boarding Kennels',
  );

  const retreats = useMemo(() => cms.retreats.allRetreats || [], [cms]);

  const states = useMemo(
    () => [
      'All',
      ...new Set(retreats.map((retreat: CmsRetreat) => retreat.address?.state)),
    ],
    [retreats],
  );

  useEffect(() => {
    if (activeState === 'All') {
      setFilteredRetreats([...retreats]);
    } else {
      setFilteredRetreats([
        ...retreats.filter((retreat) => retreat.address?.state === activeState),
      ]);
    }
  }, [activeState, retreats]);

  const breadcrumbs = useMemo(
    () => generateBreadcrumbsList(pathname),
    [pathname],
  );

  // Add function to interpolate retreat data into page template block structure
  return (
    <SiteLayout
      title="Retreats"
      isHome={false}
      cmsMeta={seoMeta}
      {...templateProps}
    >
      <BreadcrumbJsonLd itemListElements={breadcrumbs} />
      <ResponsiveContext.Consumer>
        {({ breakpoint }) => (
          <Container maxWidth="lg" className={classes.navOffset}>
            <Box textAlign="center">
              <Typography variant="h1">Retreats</Typography>
              <Box
                display="flex"
                justifyContent="center"
                className={classes.filter}
                mb={5}
              >
                {states.map((state, i) => (
                  <Button
                    variant={activeState === state ? 'outlined' : 'text'}
                    key={i}
                    className={classes.filterBtn}
                    onClick={() => setActiveState(state)}
                  >
                    {state}
                  </Button>
                ))}
              </Box>
              <Box id="box-page-template">
                <Grid container spacing={3}>
                  {filteredRetreats.map((retreat, i) => {
                    return (
                      <Grid
                        item
                        key={retreat.id}
                        xs={12}
                        md={i % 3 === 0 ? 12 : 6}
                        style={{ textAlign: 'center', marginBottom: 80 }}
                      >
                        <Link
                          to={`/retreats${retreat.slug}`}
                          className={classes.retreatLink}
                        >
                          <div style={{ position: 'relative' }}>
                            <img
                              src={retreat.photos?.[0]?.src}
                              alt={retreat.photos?.[0]?.name}
                              className={
                                i % 3 === 0
                                  ? classes.bigImage
                                  : classes.smallImage
                              }
                            />
                            {retreat.id === darwinRetreatId && (
                              <Typography className={classes.flag}>
                                COMING SOON
                              </Typography>
                            )}
                          </div>
                          <Typography
                            variant="caption"
                            className={classes.suburb}
                          >
                            {retreat.address?.suburb}
                          </Typography>
                          <Typography variant="h4" className={classes.title}>
                            {retreat.title}
                          </Typography>
                        </Link>
                      </Grid>
                    );
                  })}
                </Grid>
              </Box>
            </Box>
          </Container>
        )}
      </ResponsiveContext.Consumer>
    </SiteLayout>
  );
};

export default Retreat;
